<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="container">
          <div class="welcome-text px-4">
            <h1>{{ $t('signup.title') }}</h1>
          </div>
          <div class="px-4">
            <div class="steps" v-if="this.$i18n.locale === 'ru'">
              <span class="current">{{ $t('signup.step') }} 2</span>
              <span class="total">{{ $t('signup.out-of') }} 4</span>
            </div>
            <div class="steps" v-else-if="this.$i18n.locale === 'kz'">
              <span class="current">4</span>
              <span class="total">{{ $t('signup.out-of') }} 2{{ $t('signup.ending') }}</span>
            </div>
            <div class="signin-form row">
              <div class="col-md-12" style="margin-bottom: 100px;">
                <div class="row">
                  <div class="col-lg-6 col-md-12">
                    <form-group
                        :field="$v.account.last_name"
                        :label="$t('profile.last-name-label') + $t('references.required-field')"
                        name="last_name"
                    >
                      <el-input
                          :placeholder="$t('profile.last-name-placeholder')"
                          v-model="account.last_name"
                          @change="$v.account.last_name.$touch()"
                      />
                    </form-group>
                    <form-group
                        :field="$v.account.first_name"
                        :label="$t('profile.first-name-label') + $t('references.required-field')"
                        name="first_name"
                    >
                      <el-input
                          clearable
                          :placeholder="$t('profile.first-name-placeholder')"
                          v-model="account.first_name"
                          @change="$v.account.first_name.$touch()"
                      />
                    </form-group>
                    <form-group
                        :field="$v.account.patronymic"
                        :label="$t('profile.patronymic-label')"
                        name="patronymic"
                    >
                      <el-input
                          clearable
                          :placeholder="$t('profile.patronymic-placeholder')"
                          v-model="account.patronymic"
                      />
                    </form-group>
                    <form-group
                        :field="$v.account.birth_date"
                        :label="$t('profile.birth-date-label') + $t('references.required-field')"
                        name="birth_date"
                    >
                      <el-input
                          clearable
                          :placeholder="$t('profile.birth-date-label')"
                          v-model="account.birth_date"
                          v-mask="'##.##.####'"
                          @change="$v.account.birth_date.$touch()"
                      />
                    </form-group>
                    <form-group :field="$v.account.sex" :label="$t('references.gender') + $t('references.required-field')" name="gender">
                      <el-select v-model="account.sex" :placeholder="$t('references.gender')">
                        <el-option
                            v-for="iin in [{ value: 1, label: $t('references.gender-male') }, { value: 2, label: $t('references.gender-female') }]"
                            @change="$v.account.sex.$touch()"
                            :key="iin.value"
                            :label="iin.label"
                            :value="iin.value"
                        />
                      </el-select>
                    </form-group>
                    <form-group :field="$v.account.region_id" :label="$t('references.region-label') + $t('references.required-field')" name="region_id">
                      <el-select
                          v-model="account.region_id"
                          :placeholder="$t('references.region-placeholder')"
                          :loading="this.regions.loading"
                          @change="changeRegion"
                      >
                        <el-option
                            v-for="region in regions.data"
                            :key="region.id"
                            :label="region.name"
                            :value="region.id"
                        />
                      </el-select>
                    </form-group>
                    <form-group :field="$v.account.city_id" :label="$t('references.district-label') + $t('references.required-field')">
                      <el-select
                          v-model="account.city_id"
                          :placeholder="$t('references.district-label')"
                          :loading="this.cities.loading"
                          :disabled="!this.account.region_id"
                          @change="changeDistrict"
                      >
                        <el-option
                            v-for="city in cities.data"
                            :key="city.id"
                            :label="city.name"
                            :value="city.id"
                        />
                      </el-select>
                    </form-group>
                    <form-group :field="$v.account.locality_id" :label="$t('references.locality-label') + $t('references.required-field')" name="locality_id">
                      <el-select
                          v-model="account.locality_id"
                          :placeholder="$t('references.locality-label')"
                          :disabled="!this.account.city_id"
                          :loading="this.localities.loading"
                          @change="changeLocality"
                      >
                        <el-option
                            v-for="locality in localities.data"
                            :key="locality.id"
                            :label="locality.name"
                            :value="locality.id"
                        />
                      </el-select>
                    </form-group>
                    <form-group :field="$v.account.school_id" :label="$t('references.school-label') + $t('references.required-field')" name="school_id">
                      <el-select
                          v-model="account.school_id"
                          :placeholder="$t('references.school-label')"
                          :loading="this.schools.loading"
                          :disabled="!this.account.locality_id"
                      >
                        <el-option
                            v-for="school in schools.data"
                            @change="$v.account.school_id.$touch()"
                            :key="school.id"
                            :label="school.name"
                            :value="school.id"
                        />
                      </el-select>
                    </form-group>

                    <el-button
                        style="margin-left: 0"
                        class="btn-next"
                        @click="back"
                    >
                      {{ $t('signup.back-btn') }}
                    </el-button>
                    <el-button
                        style="color: white; background-color: #409EFF"
                        type="button"
                        class="btn-next"
                        @click="submit"
                    >
                      {{ $t('signup.next-btn') }}
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {required} from "vuelidate/lib/validators"

export default {
  name: 'UserSignupStep2',
  data() {
    return {
      regions: {
        loading: false,
        data: [],
      },
      cities: {
        loading: false,
        data: [],
      },
      localities: {
        loading: false,
        data: [],
      },
      schools: {
        loading: false,
        data: [],
      },
      account: {
        iin: '',
        phone: '',
        last_name: '', // Фамилия (обязательное поле для ввода)
        first_name: '', // Имя (обязательное поле для ввода)
        patronymic: '', // Отчество (поле для ввода)
        birth_date: '', // дата рождения - по идее можем вытащить просто с ИИН автоматически.
        sex: '', // пол - дропдаун - обязательный выбор одного значения
        region_id: '', // Область - дропдаун - обязательный выбор одного значения
        city_id: '', // Район - дропдаун с поиском - обязательный выбор одного значения
        locality_id: '', // Населенный пункт - дропдаун с поиском - обязательный выбор одного значения
        school_id: '', // Школа - дропдаун с поиском - обязательный выбор одного значения
      }
    }
  },
  validations: {
    account: {
      last_name: { required },
      first_name: { required },
      birth_date: { required },
      sex: { required },
      region_id: { required },
      city_id: { required },
      locality_id: { required },
      school_id: { required },
    }
  },
  methods: {
    getRegions() {
      this.regions.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/regions`)
          .then((res) => {
            if (res.body.data) {
              this.regions.loading = false;
              this.regions.data = res.body.data;
            }
          })
          .catch((e) => {
            this.regions.loading = false;
            console.log(e);
          });
    },
    getDistricts(regionId) {
      this.cities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/districts/${regionId}`)
          .then((res) => {
            if (res.body.data) {
              this.cities.loading = false;
              this.cities.data = res.body.data;
            }
          })
          .catch((e) => {
            this.cities.loading = false;
          });
    },
    getLocalities(districtId) {
      this.localities.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/localities/${districtId}`)
          .then((res) => {
            if (res.body.data) {
              this.localities.loading = false;
              this.localities.data = res.body.data;
            }
          })
          .catch((e) => {
            this.localities.loading = false;
          });
    },
    getSchools(localityId, query) {
      this.schools.loading = true;
      this.$http.get(`${window.API_ROOT}/api/references/schools/${localityId}`, { params: { q: query } })
          .then((res) => {
            if (res.body.data) {
              this.schools.loading = false;
              this.schools.data = res.body.data;
            }
          })
          .catch((e) => {
            this.schools.loading = false;
          });
    },
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 403) {
          this.$router.push('/')
          Vue.toastr({
            message: '',
            description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            type: 'info'
          });
        }
      } catch (error) {
        this.$router.push('/')
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      sessionStorage.setItem('account', JSON.stringify(this.account));
      this.$router.push({ name: 'signup-third-step' });
    },
    back() {
      sessionStorage.setItem('account', JSON.stringify(this.account));
      this.$router.push({ name: 'signup-first-step' });
    },
    changeRegion(regionId) {
      console.log(123);
      this.$v.account.region_id.$touch();
      this.getDistricts(regionId);
      this.account.city_id = '';
      this.account.locality_id = '';
      this.account.school_id = '';
    },
    changeDistrict(cityId) {
      this.$v.account.city_id.$touch();
      this.getLocalities(cityId);
      this.account.locality_id = '';
      this.account.school_id = '';
    },
    changeLocality(localityId) {
      this.$v.account.locality_id.$touch();
      this.getSchools(localityId);
      this.account.school_id = '';
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  beforeMount() {
    if (sessionStorage.getItem('account')) {
      this.account = {
        ...this.account,
        ...JSON.parse(sessionStorage.getItem('account'))
      };
    }

    if (this.account.region_id) this.getDistricts(this.account.region_id);
    if (this.account.city_id) this.getLocalities(this.account.city_id);
    if (this.account.locality_id) this.getSchools(this.account.locality_id);
  },
  mounted() {
    this.getRegions();
  },
  watch: {
    // 'account.region_id': function(regionId) {
    //   this.getDistricts(regionId);
    //   this.account.city_id = '';
    //   this.account.locality_id = '';
    //   this.account.school_id = '';
    // },
    // 'account.city_id': function(cityId) {
    //   this.getLocalities(cityId);
    //   this.account.locality_id = '';
    //   this.account.school_id = '';
    // },
    // 'account.locality_id': function(localityId) {
    //   this.getSchools(localityId);
    //   this.account.school_id = '';
    // },
  }
}
</script>

